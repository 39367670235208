exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-friends-js": () => import("./../../../src/pages/friends.js" /* webpackChunkName: "component---src-pages-friends-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-past-work-js": () => import("./../../../src/pages/past-work.js" /* webpackChunkName: "component---src-pages-past-work-js" */),
  "component---src-templates-footer-page-js": () => import("./../../../src/templates/footer-page.js" /* webpackChunkName: "component---src-templates-footer-page-js" */),
  "component---src-templates-studio-js": () => import("./../../../src/templates/studio.js" /* webpackChunkName: "component---src-templates-studio-js" */)
}

